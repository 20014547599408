// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import questionsPhotosStyle from "assets/jss/material-kit-pro-react/views/questionsPageSections/questionsPhotosStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Fade from "react-reveal/Fade"

// aboutImages
const useStyles = makeStyles(questionsPhotosStyle)

export default function QuestionsPhotos() {
  const {
    questionsPhoto1,
    questionsPhoto2,
    questionsPhoto3,
    questionsPhoto4,
    questionsPhoto5,
  } = useStaticQuery(graphql`
    query getQuestionsPhotos {
      questionsPhoto1: file(relativePath: { eq: "faq/faq-4.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      questionsPhoto2: file(relativePath: { eq: "faq/faq-5.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      questionsPhoto3: file(relativePath: { eq: "faq/faq-3.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      questionsPhoto4: file(relativePath: { eq: "faq/faq-1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      questionsPhoto5: file(relativePath: { eq: "faq/faq-2.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={4} sm={6}>
            <div className={classes.questionsImageWrapperSmall}>
              <Fade delay={200}>
                <GatsbyImage
                  image={questionsPhoto1.childImageSharp.gatsbyImageData}
                  className={classes.questionsImageSmall}
                  alt="Questions Photo 1"
                />
              </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={6}>
            <div className={classes.questionsImageWrapperSmall}>
              <Fade delay={300}>
                <GatsbyImage
                  image={questionsPhoto2.childImageSharp.gatsbyImageData}
                  className={classes.questionsImageSmall}
                  alt="Questions Photo 2"
                />
              </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={6}>
            <div className={classes.questionsImageWrapperSmall}>
              <Fade delay={400}>
                <GatsbyImage
                  image={questionsPhoto3.childImageSharp.gatsbyImageData}
                  className={classes.questionsImageSmall}
                  alt="Questions Photo 3"
                />
              </Fade>
            </div>
          </GridItem>
          <GridItem md={6} sm={6}>
            <div className={classes.questionsImageWrapperLarge}>
              <Fade delay={500}>
                <GatsbyImage
                  image={questionsPhoto4.childImageSharp.gatsbyImageData}
                  className={classes.questionsImageLarge}
                  alt="Questions Photo 4"
                />
              </Fade>
            </div>
          </GridItem>
          <GridItem md={6} sm={12}>
            <div className={classes.questionsImageWrapperLast}>
              <Fade delay={600}>
                <GatsbyImage
                  image={questionsPhoto5.childImageSharp.gatsbyImageData}
                  className={classes.questionsImageLast}
                  alt="Questions Photo 5"
                />
              </Fade>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
