import {
  title,
  description,
  mrAuto,
  mlAuto,
  section,
  container,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const imgRaised = imagesStyles.imgRaised
const rounded = imagesStyles.imgRounded
const imgFluid = imagesStyles.imgFluid

const questionsPhotosStyle = (theme) => ({
  title,
  description,
  mrAuto,
  mlAuto,
  container,
  section: {
    ...section,
    padding: "0 0 80px 0",
  },
  aboutImages: {
    "& img": {
      margin: "20px 0px",
    },
  },
  questionsImageSmall: {
    ...imgRaised,
    ...imgFluid,
    ...rounded,
    height: "200px",
  },
  questionsImageLarge: {
    ...imgRaised,
    ...imgFluid,
    ...rounded,
    height: "325px",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
  questionsImageLast: {
    ...imgRaised,
    ...imgFluid,
    ...rounded,
    height: "325px",
  },
  questionsImageWrapperSmall: {
    height: "200px",
    margin: "1rem",
  },
  questionsImageWrapperLarge: {
    height: "325px",
    margin: "1rem",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
  questionsImageWrapperLast: {
    height: "325px",
    margin: "1rem",
  },
})

export default questionsPhotosStyle
